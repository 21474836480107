import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import ConsoleView from "./ConsoleView";
import ContentView from "./ContentView";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/console" element={<ConsoleView/>} />
        <Route path="/" element={<ContentView/>} />

        {/* Handles invalid urls back to /about */}
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
