import styled from "styled-components"
import { Formik, Form, Field } from "formik";
import { InputField1, InputField2, TextAreaStyle } from "../utility/Fields";
import { ConfirmButton, LinkButton } from "../utility/Buttons";
import { WidthAdjustmentDiv } from "../utility/GlobalStyles";
import { B2T, B3, B3T, B4, T3, T4 } from "../utility/TypographyStyles";
import { addNote, deleteNote, getNotes } from "../controllers/DBController";
import { useEffect, useState } from "react";

import chevron from "../assets/chevronLeftIcon.png"
import { getDefaultNoteMessage } from "../controllers/DefaultMessageController";

export default function ConsolePageView() {

    let logoLink = "https://firebasestorage.googleapis.com/v0/b/nazarovgeoweb.appspot.com/o/contact%2FnazarovGeo.png?alt=media&token=3bd73018-ca2a-4baa-8096-bb65330c8ed5"

    const [notes, setNotes] = useState([])

    function updateNotes() {
        getNotes()
        .then(notes=>setNotes(notes))
        .catch(error=>alert(error.message))
    }

    useEffect(() => {
      updateNotes()
    }, [])
    

    return (
        <PageWrapper>
            <Content>
                <Spacer/>
                <Element><T3>Contact Page</T3></Element>
                <Element><B2T>console</B2T></Element>

                <TinySpacer/>

                {/* NEW NOTE EDITOR SECTION */}
                <Formik 
                    initialValues={{number: "", name: "", note: ""}}
                    onSubmit={(values, {resetForm})=>{
                        console.log("submitted new note", values)
                        if (values.number === "" || values.name === "" || values.note === "") {
                            alert("Please fill in all fields.")
                            return
                        }

                        if (isNaN(values.number)) {
                            alert("The note number must be a number.")
                            return
                        }
                        //send request
                        addNote(values.number, values.name, values.note)
                        .then(newNote=> {
                            resetForm()
                            //update all notes array
                            updateNotes()
                        })
                        .catch(error => {
                            alert(error.message)
                            console.log(error)
                        })
                    }}
                >
                { props =>(
                    <Form noValidate>
                        <Section>

                            <T4>New Note:</T4>

                            <InputField1 
                                type="text" 
                                name="number" 
                                value={props.values.number} 
                                placeholder="Assign Number"
                                isValid={true}
                            />
                            <InputField1 
                                type="name" 
                                name="name" 
                                value={props.values.name} 
                                placeholder="Name" 
                                isValid={true}
                            />

                            <ConfirmButton isGrey={true} text={"Paste Default"} action={() => {
                                const message = getDefaultNoteMessage(props.values.number)
                                props.setFieldValue("note", message)
                            }} />

                            <InputField2
                                type="text" 
                                as={TextAreaStyle}
                                rows="4"
                                name="note" 
                                value={props.values.note} 
                                placeholder="Your note" 
                                isValid={true}
                                onChange={(e) => {
                                    props.setFieldValue("note", e.target.value)
                                }}
                                // style={{width: "100%", height: "100px", marginBottom: "10px"}}
                            />
                            <ConfirmButton text={"Publish"} action={props.handleSubmit} />
                        </Section>
                    </Form>
                )}
                </Formik>
                <TinySpacer/>
                {notes
                ?
                <Section>
                    <T4>All Notes:</T4>
                    
                    {notes.map((element)=>{
                        return (
                            <NoteFolderView note={element} action={()=> {
                                //remove
                                deleteNote(element)
                                //update
                                updateNotes()
                            }}/>
                        )
                    })}
                </Section>
                :<div/>}
                <Spacer/>

                <Logo src={logoLink} alt="brandLogo"/>

            </Content>
        </PageWrapper>
    )
}

// types: 0-note; 1-link; 2-text
function NoteFolderView(props) {

    const [open, setOpen] = useState(false)
    const note = props.note

    return (
        <FolderWrapper open={open} onClick={()=>{setOpen(current => !current)}}>
            <HeaderWrapper>
                <B3>{note.noteID}: {note.name}</B3>

                <Chevron src={chevron} open={open} />
            </HeaderWrapper>

            <NoteBody>{note.noteBody}</NoteBody>

            <FolderButtonWrapper>
                <LinkButton text={"Delete"} action={() => {
                    props.action()
                }} isRed={true}/>
            </FolderButtonWrapper>

        </FolderWrapper>
    )
}

const FolderWrapper = styled.div`
    width: 100%;
    min-height: 55px;
    max-height: ${props=> props.open === true ? "1000px" : "55px"};
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;

    transition: all 0.2s ease-in-out;
`

const Chevron = styled.img`
    height: 25px;
    object-fit: contain;
    transform: rotate(${props=>props.open === true ? "90deg" : "0deg"});
    transition: all 0.2s ease-in-out;
    opacity: 0.5;
`

const HeaderWrapper = styled.div`
    min-height: 55px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* margin: 0px 10px; */

    > * {
        margin: 0px 10px;
    }
`

const NoteBody = styled(B3T)`
    width: 100%;
    margin: 0px 10px;
    margin-bottom: 16px;
    word-break: break-all;
`

const FolderButtonWrapper = styled.div`
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const PageWrapper = styled(WidthAdjustmentDiv)`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
`

const Content = styled.div`
    width: 100%;
`

const Spacer = styled.div`
    height: 75px;
`

const TinySpacer = styled.div`
    height: 25px;
`

const Element = styled.div`
    margin: 0px 16px;
`

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 8px;
    margin: 0px 16px;
`

const Logo = styled.img`
    width: 50%;
    left: -50%;
    transform: translateX(50%);
    object-fit: contain;
`