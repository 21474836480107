import { Field } from "formik";
import styled from "styled-components";
import { Colors } from "./Colors";

export const InputField1 = styled(Field)`
    width: 100%;
    height: 75px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: white;
    border: 1px solid white;
    border-radius: 17px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Albert Sans', sans-serif;
    outline: none !important;
    ::-webkit-input-placeholder {
        margin-left: 10px;
    }

    :-moz-placeholder {
        margin-left: 10px;
    }

    ::placeholder {
        margin-left: 10px;
    }
`

export const InputField2 = styled(Field)`
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: white;
    border: 1px solid white;
    border-radius: 17px;
    font-size: 18px;
    font-weight: 400;
    outline: none !important;

    > * {
        margin-left: 10px;
    }
`

export const InputField3 = styled(Field)`
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: rgba(0,0,0,0);
    border: 0px solid rgba(0,0,0,0);
    /* border-radius: 8px; */
    font-size: 18px;
    font-weight: 400;
    font-family: 'Albert Sans', sans-serif;
    color: white;
    outline: none !important;
    
    ::-webkit-input-placeholder {
        color: white;
    }

    :-moz-placeholder {
        color: white;
    }

    ::placeholder {
        color: white;
    }
`

export const TextAreaStyle = styled.textarea`
    resize: none;
    font-family: 'Albert Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
`
