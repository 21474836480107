import { app } from "./FirebaseController";
import { 
    getAuth, 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithCustomToken
} from "@firebase/auth";

const auth = getAuth(app)

export async function logIn(email, password) {

    console.log("hey")

    try {
        let userCredential = await signInWithEmailAndPassword(auth, email, password)
        const user = userCredential.user;
        console.log("LOGGED IN with email & password", user)
        return true
    } catch (error) {
        const errorMessage = error.message;
        console.log(errorMessage)
        alert(errorMessage)
    }
}

export async function logOut() {
    auth.signOut()
}