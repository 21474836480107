import styled from "styled-components"
import { useState, useEffect } from "react"
import { WidthAdjustmentDiv } from "../utility/GlobalStyles"
import { B2, B2T, B3, B3T, B4, B4T, S3, S4, T2, T3, T4 } from "../utility/TypographyStyles";
import { Formik, Form, Field } from "formik";
import { InputField1, InputField2, InputField3, TextAreaStyle } from "../utility/Fields";
import { getAllPageInfo, getNoteByID } from "../controllers/DBController";

import chevron from "../assets/chevronLeftIcon.png"
import noteBackground from "../assets/background.png"
import { LinkButton } from "../utility/Buttons";
import { Colors } from "../utility/Colors";

import pups from "../assets/pupsik.png"

export default function ContentView() {

    let profileURL = "https://firebasestorage.googleapis.com/v0/b/nazarovgeoweb.appspot.com/o/contact%2Fbabik.jpg?alt=media&token=dd6dce39-b35b-4fe1-a5e8-aca33b02cf12"
    let logoLink = "https://firebasestorage.googleapis.com/v0/b/nazarovgeoweb.appspot.com/o/contact%2FnazarovGeo.png?alt=media&token=3bd73018-ca2a-4baa-8096-bb65330c8ed5"

    const [links, setLinks] = useState([])
    const [aboutMe, setAboutMe] = useState([])
    const [aboutLinks, setAboutLinks] = useState([])
    const [note, setNote] = useState(undefined)

    const [showPupsik, setShowPupsik] = useState(false)

    useEffect(() => {
        getAllPageInfo()
        .then(info=>{
            console.log(info)
            setLinks(info.links)
            setAboutMe(info.aboutMe)
            setAboutLinks(info.aboutLinks)
        })
        .catch(error=>alert(error.message))
    }, [])
    
    return (
        <PageWrapper>
            <Content>
                <ProfileHeader>

                    <ProfileImg src={profileURL} alt="profilePicture" />

                    <ProfileTextWrapper>
                        <T2>{"Konstantin\nNazarov"}</T2>
                    </ProfileTextWrapper>

                </ProfileHeader>

                {/* <Formik 
                    initialValues={{noteID: ""}}
                    onSubmit={values=>{
                        console.log("submitted")
                        console.log(values)
                        getNoteByID(values.noteID)
                        .then(note=>setNote(note))
                        .catch(error=>alert(error.message))
                    }}
                >
                    { props =>(
                        <Form noValidate>
                            <NoteWrapper>
                                <NoteBackground src={noteBackground} />

                                <InputWrapper>
                                    <InputField3 
                                        type="text" 
                                        name="noteID" 
                                        value={props.values.noteID} 
                                        placeholder="Enter number on the business card"
                                        isValid={true}
                                    />
                                </InputWrapper>
                            </NoteWrapper>
                        </Form>
                    )}
                </Formik> */}

                {/* Show note if exists */}
                {note
                ?
                <Section>
                    <TinySpacer/>
                    {note.name !== ""
                    ?
                    <T4>Dear {note.name},</T4>
                    :
                    <T4>Hey!</T4>
                    }
                    <Message>{note.noteBody}</Message>
                    <TinySpacer/>
                </Section>
                :<div/>}

                <TinySpacer/>

                {/* about me */}
                {aboutMe
                ?
                <Section>
                    <T4>About me:</T4>
                    <MiliSpacer/>
                    {aboutMe.map((aboutSection)=>{
                        return (
                            <TextFolderView about={aboutSection}/>
                        )
                    })}
                    {aboutLinks.map((link)=>{
                        return (
                            <LinkFolderView link={link}/>
                        )
                    })}
                </Section>
                :<div/>}
                

                <TinySpacer/>

                {/* contact informtation */}
                {links
                ?
                <Section>
                    <T4>Contact information:</T4>
                    <MiliSpacer/>
                    {links.map((link)=>{
                        return (
                            <LinkFolderView link={link}/>
                        )
                    })}
                </Section>
                :<div/>}

                {/* footer */}
                <Spacer/>

                <FooterText>This website does not collect any usage analytics to ensure full privacy.</FooterText>
                
                {/* <Spacer/> */}
                
                <Logo onClick={()=> {
                    setShowPupsik(true)
                    setTimeout(() => {
                        setShowPupsik(false)
                    }, 2000);
                }} src={logoLink} alt="brandLogo"/>

                {/* {showPupsik
                ?
                <Pupsik src={pups} show={showPupsik}/>
                :<div/>} */}

                <Pupsik src={pups} show={showPupsik}/>
                
            </Content>
        </PageWrapper>
    )
}

function TextFolderView(props) {

    const [open, setOpen] = useState(false)
    const about = props.about

    const [tapped, setTapped] = useState(false)

    return (
        <FolderWrapper tapped={tapped} open={open} onClick={()=>{
            setTapped(true)
            setOpen(current => !current)
            setTimeout(() => {
                setTapped(false)
            }, 150);
        }}>
            <HeaderWrapper>
                <S3>{about.sectionName}</S3>

                <Chevron src={chevron} open={open} />
            </HeaderWrapper>

            <NoteBody>{about.sectionBody}</NoteBody>

        </FolderWrapper>
    )
}

function LinkFolderView(props) {

    const [open, setOpen] = useState(false)
    const link = props.link

    const [tapped, setTapped] = useState(false)

    function getMessage(linkID) {
        switch (linkID) {
            case 0: return "Open"
            case 1: return "Send Email"
            case 2: return "Call"
            default: return "Follow Link"
        }
    }

    function followLink(linkID, link) {
        var followLink = ""
        switch (linkID) {
            case 0: 
                followLink = link
                break;
            case 1: 
                followLink = "mailto:" + link
                break;
            case 2: 
                followLink = "tel:" + link
                break;
            default:
                followLink = link
                break;
        }
        window.open(followLink, "_blank")
    }

    return (
        <FolderWrapper tapped={tapped} open={open} onClick={()=>{
            setTapped(true)
            setOpen(current => !current)
            setTimeout(() => {
                setTapped(false)
            }, 150);
        }}>
            <HeaderWrapper>
                <FolderHeaderLeftBlock>
                    <FolderIcon src={link.imgSrc} />
                    <S3>{link.linkName}</S3>
                </FolderHeaderLeftBlock>

                <Chevron src={chevron} open={open} />
            </HeaderWrapper>

            <LinkBody>{link.link}</LinkBody>

            <FolderButtonWrapper>
                <LinkButton text={getMessage(link.linkID)} action={() => {
                    followLink(link.linkID, link.link)
                }} />
            </FolderButtonWrapper>

        </FolderWrapper>
    )
}

const FolderHeaderLeftBlock = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: start;
`

const FolderIcon = styled.img`
    object-fit: contain;
    width: 20px;
`

const NoteWrapper = styled.div`
    position: relative;

    width:100%;
    /* width: 100%;
    height: 75px; */
`

const InputWrapper = styled.div`
    position: absolute;
    top: 10px;
    left: 16px;
    width: 95%;
    height: 75px;
    div:focus { outline: none; }
    
`

const NoteBackground = styled.img`
    height: 75px;
    width: 100%;
    object-fit: cover;
    /* position: absolute; */
`

const ProfileHeader = styled.div`
    /* width: 100%; */
    /* height: auto; */
    position: relative;
`

const ProfileTextWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    left: 16px;

    > * {
        color: white;
        text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
        white-space: pre-wrap;
    }
`

const ProfileImg = styled.img`
    object-fit: contain;
    width: 100%;
`

const FolderWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 75px;
    max-height: ${props=> props.open === true ? "1000px" : "75px"};
    /* background-color: white; */
    background-color: ${props => props.tapped === true ? Colors.lightgray : Colors.white};
    border-radius: 17px;
    overflow: hidden;

    /* opacity: ${props => props.tapped === true ? "0.8" : "1"}; */

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    /* gap: 10px; */

    transition: max-height 0.3s ease-in-out;
    /* transition: opacity 0.2s linear; */
`

const Chevron = styled.img`
    height: 15px;
    object-fit: contain;
    transform: rotate(${props=>props.open === true ? "90deg" : "0deg"});
    transition: all 0.3s ease-in-out;
    opacity: 0.5;
`

const HeaderWrapper = styled.div`
    min-height: 75px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* margin: 0px 10px; */

    > * {
        margin: 0px 10px;
    }
`

const FolderButtonWrapper = styled.div`
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Message = styled(B2)`
    text-align: justify;
    text-justify: inter-word;
`

const LinkBody = styled(B3T)`
    margin: 0px 10px;
    margin-bottom: 16px;
    /* text-align: justify;
    text-justify: inter-word; */
    word-break: break-all;
`

const NoteBody = styled(B2T)`
    margin: 0px 10px;
    margin-bottom: 16px;
    /* text-align: justify;
    text-justify: inter-word; */
`

const PageWrapper = styled(WidthAdjustmentDiv)`
    position: absolute;
    overflow-x: hidden;
`

const Content = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    overflow-x: hidden;
`

const Spacer = styled.div`
    height: 75px;
`

const TinySpacer = styled.div`
    height: 25px;
`

const MiliSpacer = styled.div`
    height: 17px;
`

const Section = styled.div`
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 6px;
    margin: 0px 16px;
`

const Logo = styled.img`
    width: 50%;
    left: -50%;
    transform: translateX(50%);
    object-fit: contain;
`

const FooterText = styled(B4T)`
    text-justify: center;
    text-align: center;
    width: 50%;
    left: -50%;
    transform: translateX(50%);
`

const Pupsik = styled.img`
    position: fixed;
    left: ${props=>props.show === true ? "20px" : "-200px"};
    bottom: 0px;
    /* left: 50%; */
    transform: rotate(90deg);
    object-fit: contain;
    height: 100px;

    transition: all 0.2s ease-in-out;
`