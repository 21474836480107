const messages = {
    seventy: "I am Konstantin Nazarov, a graduate geologist looking for opportunities to enhance my skills. We met earlier at the MINEX Eurasia conference in London. I was thrilled to attend the event and am still under the impression after our meeting. As a follow-up to our exciting conversation, I've decided to provide all my information for your convenience. I would appreciate any advice you can give to a young professional just entering the job market. Please find a short intro, my contact details, my resume, and other useful links below.",
    onetwenty: "I am Konstantin Nazarov, a graduate geologist looking for opportunities to enhance my skills. We met earlier at the Mining Legends Speaker Series event in London. I was thrilled to attend the event and am still under the impression after our meeting. As a follow-up to our exciting conversation, I've decided to provide all my information for your convenience. I would appreciate any advice you can give to a young professional just entering the job market. Please find a short intro, my contact details, my resume, and other useful links below.",
    oneeighty: "I am Konstantin Nazarov, a graduate geologist looking for opportunities to enhance my skills. We met earlier at the Mines and Money event in London on Tuesday (29.11.22). I was thrilled to attend the event and am still under the impression after our meeting. As a follow-up to our exciting conversation, I've decided to provide all my information for your convenience. I would appreciate any advice you can give to a young professional just entering the job market. Please find a short intro, my contact details, my resume, and other useful links below.",
    twoforty: "I am Konstantin Nazarov, a graduate geologist looking for opportunities to enhance my skills. We met earlier at the Mines and Money event in London on Wednesday (30.11.22). I was thrilled to attend the event and am still under the impression after our meeting. As a follow-up to our exciting conversation, I've decided to provide all my information for your convenience. I would appreciate any advice you can give to a young professional just entering the job market. Please find a short intro, my contact details, my resume, and other useful links below.",
    threehundren: "I am Konstantin Nazarov, a graduate geologist looking for opportunities to enhance my skills. We met earlier at the Mines and Money event in London on Thursday (01.12.22). I was thrilled to attend the event and am still under the impression after our meeting. As a follow-up to our exciting conversation, I've decided to provide all my information for your convenience. I would appreciate any advice you can give to a young professional just entering the job market. Please find a short intro, my contact details, my resume, and other useful links below."
}

export function getDefaultNoteMessage(noteID) {
    if (isNaN(noteID)) return ""
    const number = Number(noteID)
    if (number >= 0 && number < 24) {
        return messages.seventy
    } else if (number >= 24 && number < 50 ) {
        return messages.oneeighty
    } else if (number >= 50 && number < 74) {
        return messages.twoforty
    } else if (number >= 74 && number < 300) {
        return messages.threehundren
    } else {
        return messages.threehundren
    }
}