import { useState } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import { S4 } from "./TypographyStyles";

export function ConfirmButton(props) {
    let btnText = props.text
    let action = props.action
    let isGrey = props.isGrey

    const [tapped, setTapped] = useState(false)

    return(
        <CapsuleWrapper isGrey={isGrey} tapped={tapped} onClick={() => {
            setTapped(true)
            setTimeout(() => {
                setTapped(false)
                action()
            }, 200); 
        }}>
            <ButtonText>{btnText}</ButtonText>
        </CapsuleWrapper>
    )
}

export function LinkButton(props) {
    let btnText = props.text
    let action = props.action
    let isRed = props.isRed ? true : false

    const [tapped, setTapped] = useState(false)

    return(
        <LinkButtonWrapper isRed={isRed} tapped={tapped} onClick={() => {
            setTapped(true)
            console.log("linkbutton")
            setTimeout(() => {
                setTapped(false)
                action()
            }, 200); 
        }}>
            <LinkText>{btnText}</LinkText>
        </LinkButtonWrapper>
    )
}

const LinkButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${props=>props.isRed ? Colors.red : "rgba(0,47,136,0.5)"};

    border-radius: 25px;
    
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;

    cursor: pointer;

    opacity: ${props=>props.tapped === true ? "0.5" : "1"};
`

const CapsuleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 75px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.isGrey ? Colors.lightgray : Colors.green};

    border-radius: 18px;
    
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;

    cursor: pointer;

    opacity: ${props=>props.tapped === true ? "0.5" : "1"};
`

const ButtonText = styled(S4)`
    color: ${Colors.black};
    padding: 0px 16px;
`

const LinkText = styled(S4)`
    color: ${Colors.white};
    padding: 0px 16px;
`