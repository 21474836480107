import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { app } from "../controllers/FirebaseController";
import ConsoleAuthView from "../views/ConsoleAuthView";
import ConsolePageView from "../views/ConsolePageView";

export default function ConsoleView() {

    const auth = getAuth(app)

    const [loggedIn, setLoggedIn] = useState(false)

    onAuthStateChanged(auth, (user) => {

        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          console.log(uid + " user is logged in")
      
          setLoggedIn(true)
      
        } else {
          console.log("not logged in")
          setLoggedIn(false)
        }
      })

    return (
        <div>
            {loggedIn
            ?
                <ConsolePageView/>
            :
                <ConsoleAuthView/>
            }
        </div>
    )
}