import styled from "styled-components"
import { Formik, Form, Field } from "formik";
import { InputField1 } from "../utility/Fields";
import { ConfirmButton } from "../utility/Buttons";
import { logIn } from "../controllers/AuthController";
import { WidthAdjustmentDiv } from "../utility/GlobalStyles";

export default function ConsoleAuthView() {

    return (
        <Formik 
            initialValues={{email: "", password: ""}}
            onSubmit={values=>{
                console.log("submitted")
                console.log(values)
                logIn(values.email, values.password)
            }}
        >
            { props =>(
                <Form noValidate>
                    <PageWrapper>
                        <AuthWrapper>
                            <InputField1 
                                type="email" 
                                name="email" 
                                value={props.values.name} 
                                placeholder="Enter your email"
                                isValid={true}
                            />
                            <InputField1 
                                type="password" 
                                name="password" 
                                value={props.values.name} 
                                placeholder="Enter your password" 
                                isValid={true}
                            />
                            <ConfirmButton text={"Sign In"} action={props.handleSubmit} />
                        </AuthWrapper>
                    </PageWrapper>
                </Form>
            )}
        </Formik>
    )
}

const PageWrapper = styled(WidthAdjustmentDiv)`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
    align-items: center;
    justify-content: center;
    position: absolute;
`

const AuthWrapper = styled.div`
    /* width: 100vw;
    height: 100vh; */
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
`



