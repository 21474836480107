export const Colors = {
    black: "#000000",
    white: "#FFFFFF",
    background: "#FFFCF8",
    red: "#FF0000",
    yellow: "#FFEE00",
    blue: "#00B2CA",
    purple: "#5C7AFF",
    green: "#20FF00",
    lightgray: "#EFEFEF"
}