import {
    getFirestore, doc, setDoc, addDoc,
    updateDoc, increment, arrayUnion, arrayRemove,
    getDoc, collection, getDocs,
    where, query
} from "firebase/firestore";
import { getDefaultNoteMessage } from "./DefaultMessageController";

const db = getFirestore();

function CustomError(message) {
    this.message = message
    this.name = "CustomError"
}

export async function addNote(number, name, text) {
    try {
        const noteItems = doc(db, "contactpage", "notes")

        const oldDoc = await getDoc(noteItems)
        if(oldDoc.exists()) {
            //check if there exists note with this id
            const allNotes = oldDoc.data().noteArray
            const result = allNotes.find((element)=>{ return element.noteID === number})
            if (result) {
                console.log(result)
                throw new CustomError("Note with this number already exists.")
            }
        } else {
            //throw error
            throw new CustomError("Internal Error: no doc notes i guess")
        }

        //update
        const newDoc = await updateDoc(noteItems, {
            "noteArray": arrayUnion({
                noteID: number,
                noteBody: text,
                name: name
            })
        })
        return true
    } catch (error) {
        throw error
    }
}

export async function getNotes() {
    try {
        const noteItems = doc(db, "contactpage", "notes")
        const snap = await getDoc(noteItems)
        if (snap.exists()) {
            const notes = snap.data().noteArray
            console.log(notes)
            return notes.reverse()
        } else {
            throw new CustomError("Internal error: no document found.")
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getAllPageInfo() {
    //get links, getAbout -> send together
    try {
        const allLinks = await getLinks()
        const links = allLinks.slice(0, 3)
        const aboutLinks = allLinks.slice(3,6)
        const aboutMe = await getAbout()
        return {
            links: links,
            aboutMe: aboutMe,
            aboutLinks: aboutLinks
        }
    } catch (error) {
        throw error
    }
}

export async function getLinks() {
    try {
        const docSnap = doc(db, "contactpage", "links")
        const snap = await getDoc(docSnap)
        if (snap.exists()) {
            const links = snap.data().linkArray
            return links
        } else {
            throw new CustomError("Resource unavailable.")
        }
    } catch (error) {
        throw error
    }
}

export async function getAbout() {
    try {
        const docSnap = doc(db, "contactpage", "aboutMe")
        const snap = await getDoc(docSnap)
        if (snap.exists()) {
            const aboutMeData = snap.data().aboutMeSections
            return aboutMeData
        } else {
            throw new CustomError("Resource unavailable.")
        }
    } catch (error) {
        throw error
    }
}

export async function getNoteByID(noteID) {
    try {
        if (isNaN(noteID)) {throw new CustomError("Please type in a number.")}
        const allNotes = await getNotes()
        const result = allNotes.find((element)=>{ return element.noteID === noteID})
        if (result) {
            return result
        } else {
            //give custom based on range
            const number = Number(noteID)
            return {
                noteID: noteID,
                name: "",
                noteBody: getDefaultNoteMessage(noteID)
            }
        }
    } catch (error) {
        throw error
    }
}

export async function deleteNote(note) {
    try {
        console.log("deleting")
        const snap = doc(db, "contactpage", "notes")
        const result = await updateDoc(snap, {
            noteArray: arrayRemove(note)
        })
        return true
    } catch (error) {
        throw error
    }
}